import { createGlobalStyle } from 'styled-components';

import normalize from './normalize'
import basecss from "./basecss"

const GlobalStyles = createGlobalStyle`
  ${normalize};
  ${basecss};
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
      color: ${props => props.theme.color.black.regular};
      font-family: ${props => props.theme.font.primary};
  }
  
  .text-primary {
      color: ${props => props.theme.color.primary}
  }
  .text-white {
    color: ${props => props.theme.color.white}
  }
  .text-black {
    color: ${props => props.theme.color.black.regular}
  }
  .text-content {
    color: ${props => props.theme.color.black.light}
  }
  .text-gray {
    color: ${props => props.theme.color.gray.regular}
  }
  .text-graydark {
    color: ${props => props.theme.color.gray.dark}
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .bg-primary {
    background-color: ${props => props.theme.color.primary}
  }
  .bg-white {
    background-color: ${props => props.theme.color.white}
  }
  .bg-black {
    background-color: ${props => props.theme.color.black.regular}
  }
  .bg-skill-item {
    background-color: ${props => props.theme.color.black.gray}
  }
  .svg-icon {
      width: 4em;
      height: 4rem;
      margin-right: 1rem;
      fill: ${props => props.theme.color.primary}
  }
  .svg-icon:hover {
      fill: ${props => props.theme.color.black.regular}
  }
  .icon-know {
      width: 4rem;
      height: 4rem;
      fill: ${props => props.theme.color.white}
  }
  .timeline-year {
      position: absolute;
      top: -4px;
      left: -80px;
      color: ${props => props.theme.color.white};
      font-size: 80%;
      font-weight: bold;
      background-color: ${props => props.theme.color.primary};
      padding: 4px
  }
  .timeline-year::after {
      border-width: 8px 0 8px 10px;
      border-color: transparent transparent transparent ${props => props.theme.color.primary};
      content: '';
      position: absolute;
      bottom: 7px;
      width: 0;
      height: 0;
      border-style: solid;
  }
  .timeline-dot {
      width: 2px;
      height: 100%;
      background: ${props => props.theme.color.black.regular};
      left: -30px;
      top: 0;
      position: absolute;
  }
  .timeline-dot::before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${props => props.theme.color.black.regular};
      border: 3px solid ${props => props.theme.color.black.regular};
      position: absolute;
      left: -7.5px;
  }
  .timeline-dot::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${props => props.theme.color.black.regular};
      border: 2px solid #000;
      position: absolute;
      left: -7.5px;
  }
  .timeline-item {
      margin: 20px 60px 60px;
      position: relative;
  }
  .text-small {
      font-size: 0.8rem
  }
  .border-top-gray {
      border-style: solid;
      border-color: ${props => props.theme.color.gray.regular};
      border-width: 1px 0px 0px;
  }
  .section-title {
      font-weight: 400;
      min-height: 115px;
      font-family: Oswald, sans-serif;
      position: relative;
      font-size: 54px;
      line-height: 54px;
  }
  .section-title h2 {
      font-size: 3.5rem;
      line-height: 4rem;
  }
  .full-screen {
      min-height: ${props => props.theme.screen.sectionMinHeight}
  }
  .nav-link {
      display: inline-block;
      text-decoration: none;
      transition: color 0.2s ease;
      color: ${props => props.theme.color.black.regular};
      text-transform: uppercase;
      font-family: font-family: ${props => props.theme.font.secondary};
      font-size: 1.2rem;
      font-weight: 700;
      padding: 1rem 0.5rem 1rem 0.5rem
      line-height: 4rem
      height: 4rem;
      }
  .nav-link:active {
      color: ${props => props.theme.color.primary};
  }
  .nav-link:active .nav-icon {
      fill: ${props => props.theme.color.primary};
  }
  .nav-active a {
      color: ${props => props.theme.color.primary};
  }
  .nav-active .nav-icon {
      fill: ${props => props.theme.color.primary};
  }
  .nav-icon {
      height: 2.5rem;
      fill: ${props => props.theme.color.black.regular};
      display: none;
      margin: auto;
  }
  .work-tags {
      color: ${props => props.theme.color.white};
      background-color: ${props => props.theme.color.black.regular};
      font-size: 0.9rem;
      margin-right: 0.1rem;
      padding: 0.1rem
  }
  .skill-image{
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      width: 100%;
      min-height: ${props => props.theme.screen.sectionImageMobileHeight}
  }
  .section-image{
    background-color: #090F11;
    background-repeat: no-repeat;
    background-position: center top;
    width: 100vw;
    min-height: ${props => props.theme.screen.sectionImageMobileHeight}
  }
  @media (min-width: ${props => props.theme.screen.sm}) {
    .skill-image{
        min-height: 700px
    }
    
    .section-image{
        min-height: ${props => props.theme.screen.sectionMinHeight} 
    }
  }
  @media (max-width: ${props => props.theme.screen.mobileMaxWidth}) {
      .nav-link {
           font-size: 0.5rem;
           line-height: 0.5rem;        
      }
      .nav-icon {
          display: block;
      }
  }   
`

export default GlobalStyles;