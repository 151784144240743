const theme = {
    font: {
        primary: `'Raleway', serif`,
        secondary: `'Oswald', serif`,
    },
    color: {
        white: '#FFF',
        black: {
            light: '#333',
            lighter: '#666666',
            gray: '#434347',
            regular: '#090F11',
        },
        gray: {
            regular: '#8B8B93',
            dark: '#666'
        },
        primary: '#449DD1',
    },
    colorClass: {
        textPrimary: 'text-primary',
        textWhite: 'text-white',
        textBlack: 'text-black',
        bgPrimary: 'bg-primary',
        bgWhite: 'bg-white',
        bgBlack: 'bg-black'
    },
    screen: {
        sm: '600px',
        md: '900px',
        pageWidth: "1200px",
        pageMinHeight: "768px",
        navbarHeight: "5rem",
        navbarHeightMobile: "3.5rem",
        mobileMaxWidth: "900px",
        sectionMinHeight: "850px",
        sectionScrollOffset: 50,
        sectionImageMobileHeight: "400px"
    }
};

export default theme;