import * as React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import {NavIconType} from "../../interfaces"
import IconNav from "../icons/IconNav"
import theme from '../../styles/theme'

type Props = {
    title: string,
    link: string,
    icon: NavIconType
}

const NavItem: React.FC<Props> = ({
    title,
    link,
    icon
                                  }) => {

    const id = 'nav-link-' + link
    const aProps = {
        id: id,
        className: 'nav-link',
        title: title,
        href: '#' + link
    }

    return (
    <div>
        <AnchorLink {...aProps}>
            <div><IconNav type={icon} /></div>
            <div>{title}</div>
        </AnchorLink>
    </div>
)}

export default NavItem