export const Colors = {
    blue: "#449DD1",
    white: "#FFF",
    black: "#090F11",
    blackLight: "#333",
    blackGray: "#434347",
    grayDark: "#666",
    gray: "#8B8B93"
}

export const Sizing = {
    pageWidth: "1200px",
    pageMinHeight: "768px",
    navbarHeight: "5rem",
    navbarHeightMobile: "3.5rem",
    mobileMaxWidth: "900px",
    sectionMinHeight: "850px",
    smWidth: "600px",
    mdWidth: "900px"
}

export const ColorClass = {
    textPrimary: 'text-primary',
    textWhite: 'text-white',
    textBlack: 'text-black',
    bgPrimary: 'bg-primary',
    bgWhite: 'bg-white',
    bgBlack: 'bg-black'
}