import * as React from 'react'
import Translation from '../../data/translations'

const NavContactIcon: React.FC = ( ) => {
    return(
        <svg className='nav-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{Translation.iconNavContact}</title>
            <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/>
        </svg>
    )
}

export default NavContactIcon