import { Translation } from '../interfaces'

const translationCs: Translation = {
    iconCloud: "Azure cloudové služby",
    iconCode: "Webové aplikace",
    iconDesktop: "Desktopové aplikace",
    iconGithub: "GitHub",
    iconGlobe: "Web",
    iconLinkedIn: "LinkedIn",
    iconNavContact: "symbol obálka",
    iconNavHome: "Úvod",
    iconNavService: "symblol služby",
    iconNavStory: "Práce",
    iconPhone: "Mobilní aplikace",
    myPhoto: "Moje fotka",
    locale: "cs",
    pageDescription: "Jaroslav Červenka - IT vývojář, web designer, freelancer a nadšenec do nových technologií.",
    contactGetInTouch: "Zůstaňme ve spojení",
    contactTitle: "Kontakt",
    sectionTitleContact: "My contact",
    workDevTitle: "Vývojář",
    workElxLeadText: "Vedení a vývoj frontendu webového informačního systému, tvorba nástrojů na podporu modelování architektury.",
    workElxLeadTitle: "Vedoucí vývoje frontend",
    workEmbedText: "Analýza, vývoj a rozšiřování modulů informačního systému pro nebankovní peněžní sektor.",
    workOriSeniorDevText: "Vývoj serverové části pro E-commerce platformu nasazovanou do celého světa, automatizace nasazování a UI testování, serverová část S REST API pro mobilní aplikaci.",
    sectionTitleSkills: "My skills",
    skillsText: "Uvádím seznam hlavních jazyků, ale zajímám se také o nové metody vývoje a jsem nadšený z funkcionálního programování.",
    skillsTitle: "Co potřebujete vědět",
    workMobDevText: "Návrh architektury nativní aplikace pro iOS, vytvoření prototypu a CI/CD, výběr nástrojů a platformy pro vývoj.",
    workMobDevTitle: "Mobilní vývojář",
    workPlatformArchText: "Rozvoj platformy pro vývoj, analýza a řešení výkonových problémů, automatizace CI/CD, mikroslužby, návrhy řešení v Microsoft Azure, vývoj webového portálu.",
    workPlatformArchTitle: "Platform architekt",
    sectionTitleStory: "My story",
    knowCloudText: "Návrh architektury, vývoj, monitorování, automatizace nasazování a škálování.",
    knowCloudTitle: "Azure cloudové služby",
    knowDesktopText: "Návrh a vývoj komplexních aplikací na platformách .Net a .Net Core, analýza a řešení problémů.",
    knowDesktopTitle: "Desktopové aplikace",
    knowMobileText: "Zaměřuji se na  nativní aplikace pro OS Android a iOS s použitím jazyků Kotlin, Swift, TypeScript a frameworku React Native a Flutter.",
    knowMobileTitle: "Mobilní aplikace",
    knowWebAppText: "Vyvíjím moderní webové aplikace s použitím React a TypeScriptu, Next.JS, head-less CMS, GrqphQL AMP a .Net Core.",
    knowWebAppTitle: "Webové aplikace",
    knowledge: "Znalosti",
    sectionTitleMyServices: "My services",
    sectionTitleAboutMe: "About me",
    aboutMeText: "Zabývám se vývojem mobilních a webových aplikací, návrhem architektury v Microsoft Azure a automatizací procesů. Mám rád 'Clean Code',  defenzivní programování, čistý a funkční design.",
    navAbout: "Úvod",
    navContact: "Kontakt",
    navServices: "Služby",
    navStory: "Práce",
    title: 'Jaroslav Červenka',
    aboutMeTitle: 'Jsem Jaroslav Červenka, IT vývojář, web designer, freelancer a nadšenec do nových technologií.',
    workFullStackTitle: 'Full-stack vývojář',
    workNotinoText: 'E-commerce frontend, backend, administrace, mikroservisní služby a automatizace CI/CD.'
};

/*
const translationEn: Translation = {
    contactGetInTouch: "",
    contactTitle: "",
    workDevTitle: "Developer",
    workElxLeadText: "Frontend lead",
    workElxLeadTitle: "",
    workEmbedText: "",
    workOriSeniorDevText: "",
    sectionTitleSkills: "My skills",
    skillsText: "",
    skillsTitle: "What you need to know",
    workMobDevText: "Application architecture, proof of concept, prototypes, CI/CD ",
    workMobDevTitle: "Mobile developer",
    workPlatformArchText: "",
    workPlatformArchTitle: "Platform architect",
    sectionTitleStory: "My story",
    knowCloudText: "",
    knowCloudTitle: "Azure Cloud",
    knowDesktopText: "",
    knowDesktopTitle: "Desktop development",
    knowMobileText: "Mobile development",
    knowMobileTitle: "",
    knowWebAppText: "",
    knowWebAppTitle: "",
    knowledge: "",
    sectionTitleAboutMe: "About me",
    sectionTitleMyServices: "My services",
    sectionTitleContact: "My contact",
    aboutMeText: "", aboutMeTitle: "",
    navAbout: "About me",
    navContact: "Contact",
    navServices: "Services",
    navStory: "Story",
    title: 'Jaroslav Červenka'
};
 */

export default translationCs