import * as React from 'react'
import styled from 'styled-components';

import {Sizing} from "../Theme"

const Footer: React.FC = () => (
    <FooterElement className='bg-black'>
        <div className='center p2'>
            <span className='text-graydark'>© Copyright  2019. All Right Reserved, by Jaroslav Červenka</span>
        </div>
    </FooterElement>
)

const FooterElement = styled.footer`
@media (max-width: ${Sizing.mobileMaxWidth}) {
    footer {
        margin-bottom: ${Sizing.navbarHeightMobile}
    }
}
`

export default Footer