
export type Translation = {
    locale: string,
    myPhoto: string,
    iconCloud: string,
    iconCode: string,
    iconDesktop: string,
    iconGithub: string,
    iconGlobe: string,
    iconLinkedIn: string,
    iconNavContact: string,
    iconNavHome: string,
    iconNavService: string,
    iconNavStory: string,
    iconPhone: string,
    title: string,
    navAbout: string,
    navServices: string,
    navStory: string,
    navContact: string,
    aboutMeTitle: string
    aboutMeText: string,
    sectionTitleAboutMe: string,
    sectionTitleMyServices: string,
    sectionTitleSkills: string,
    sectionTitleContact: string,
    knowledge: string,
    knowWebAppTitle: string,
    knowWebAppText: string,
    knowMobileTitle: string,
    knowMobileText: string,
    knowDesktopTitle: string,
    knowDesktopText: string,
    knowCloudTitle: string,
    knowCloudText: string,
    sectionTitleStory: string,
    workMobDevTitle: string,
    workMobDevText: string,
    workPlatformArchTitle: string,
    workPlatformArchText: string,
    workDevTitle: string,
    workOriSeniorDevText: string,
    workElxLeadTitle: string,
    workElxLeadText: string,
    workEmbedText: string,
    skillsTitle: string,
    skillsText: string
    contactTitle: string,
    contactGetInTouch: string,
    pageDescription: string,
    workFullStackTitle: string,
    workNotinoText: string
}

export enum SectionType {
    white = 'white',
    black = 'black',
    blackWithBorder = 'blackWithBorder',
    color = 'color',
    image = 'image'
}

export enum IconType {
    linkedIn = 'linkedIn',
    linkedInWhite = 'linkedInWhite',
    github = 'github',
    githubWhite = 'githubWhite',
    code = 'code',
    phone = 'phone',
    cloud = 'cloud',
    desktop = 'desktop'
}

export enum NavIconType {
    about = 'about',
    services = 'services',
    story = 'story',
    contact = 'contact'
}

export interface KnowledgeItemData {
    title: string,
    text: string,
    icon: IconType
}

export interface SkillItemData {
    id: number,
    title: string,
    percent: number
}

export interface WorkItemData {
    employer: string,
    position: string,
    text: string,
    year: number,
    tags: string[]
}