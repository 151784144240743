import * as React from 'react'
import { ThemeProvider } from 'styled-components';

import Footer from "../footer/Footer"
import Header from "../header/Header"
import SEO from "../seo"
import theme from '../../styles/theme';
import GlobalStyles from '../../styles/GlobalStyles';

type Props = {
    title?: string
}

const Layout: React.FC<Props> = ({children}) => (
    <ThemeProvider theme={theme}>
        <>
            <SEO title="Home" />
            <GlobalStyles />
            <Header />
            {children}
            <Footer />
        </>
    </ThemeProvider>
)
export default Layout
