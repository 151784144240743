import * as React from 'react'
import styled from 'styled-components';
import Scrollspy from 'react-scrollspy';

import NavItem from "./NavItem"
import {NavIconType} from "../../interfaces"
import Translation from '../../data/translations'
import theme from '../../styles/theme'

interface NavLink {
    text: string
    link: string,
    icon: NavIconType
}

const NavBar: React.FC = () =>{

    const navLinks: Array<NavLink> = [
        {text: Translation.navAbout, link: "about", icon: NavIconType.about},
        {text: Translation.navServices, link: "services", icon: NavIconType.services},
        {text: Translation.navStory, link: "story", icon: NavIconType.story},
        {text: Translation.navContact, link: "contact", icon: NavIconType.contact}
        ]

    return (
        <Nav>
            <div className="links navbar-collapse">
                <div className='clearfix navbar-container'>
                    <Scrollspy
                        items={navLinks.map(item => item.link)}
                        currentClassName="nav-active"
                        componentTag='div'
                    >
                    {navLinks.map(item => {
                        return <div className='col col-3 ' key={item.link}><NavItem title={item.text} link={item.link} icon={item.icon} /></div>
                    })}
                </Scrollspy>
                </div>
            </div>
        </Nav>
)}

const Nav = styled.nav`
    position: relative;
    flex: 1;
    min-height: ${props => props.theme.screen.navbarHeight};
    display: flex;
    justify-content: space-between;
    align-items: center;
                           
    .links {
        display: flex;
        align-items: center;
        z-index: 1;
        pointer-events: auto;
    }
    .navbar-container {
        width: 600px;                  
    }
    @media (max-width: 340px) {
        .navbar-container {
             width: 320px;
        }
    }
    @media (min-width: 341px) and (max-width: ${props => props.theme.screen.mobileMaxWidth}) {
        .navbar-container {
             width: 350px;
        }
        nav {
            height: ${props => props.theme.screen.navbarHeightMobile}
        }
    }
`

export default NavBar