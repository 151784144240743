import * as React from 'react'
import Translation from '../../data/translations'

const NavServiceIcon: React.FC = ( ) => {
    return(
        <svg className='nav-icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <title>{Translation.iconNavService}</title>
            <path d="M1.926 7l-.556-3h21.256l-.556 3h-20.144zm1.514-5l-.439-1.999h17.994l-.439 1.999h-17.116zm-3.44 7l2.035 14.999h19.868l2.097-14.999h-24zm3.782 13l-1.221-9h18.86l-1.259 9h-16.38z"/>
        </svg>
    )
}

export default NavServiceIcon