import * as React from 'react'
import styled from 'styled-components';

import NavBar from "../navbar/NavBar"

const Header: React.FC = () => (
    <HeaderWrapper>
        <div className="fixed-container active text-white bg-white">
            <div className="navbar-desktop">
                <NavBar />
            </div>
        </div>
    </HeaderWrapper>
)

const HeaderWrapper = styled.header`
    position: relative;
    word-wrap: break-word;
    min-height: ${props => props.theme.screen.navbarHeight};

    .fixed-container {
        position: fixed;
        z-index: 100;
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: ${props => props.theme.screen.navbarHeightMobile};
        min-height: ${props => props.theme.screen.navbarHeight};
        transition: all .3s;
        box-shadow: 0px 6px 20px rgba(0,0,0,0.4);
        pointer-events: auto;
        top: auto;
        bottom: 0;
    }
    .navbar-desktop {
        max-width: 1024px;
        text-align: center;
        margin: 0 auto;
    }
    @media (min-width: ${props => props.theme.screen.mobileMaxWidth}) {
        .fixed-container {
            top: 0;
            bottom: auto;
            min-height: ${props => props.theme.screen.navbarHeight};
        }
        header {
            min-height: ${props => props.theme.screen.navbarHeight};
        }
    }
`;

export default Header