import * as React from 'react'
import {NavIconType} from "../../interfaces"
import NavHomeIcon from "./NavHomeIcon"
import NavServiceIcon from "./NavServiceIcon"
import NavStoryIcon from "./NavStoryIcon"
import NavContactIcon from "./NavContactIcon"

type Props = {
    type: NavIconType
}

const IconNav: React.FC<Props> = ({type}) => {

    let icon = undefined

    switch(type) {
        case NavIconType.about:
            icon = (<NavHomeIcon />)
            break
        case NavIconType.services:
            icon = (<NavServiceIcon />)
            break
        case NavIconType.story:
            icon = (<NavStoryIcon />)
            break
        case NavIconType.contact:
            icon = (<NavContactIcon />)
            break
        default:
            throw `Icon type ${type} is not supported.`
    }

    return icon
}

export default IconNav